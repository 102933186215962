<template>
    <!-- start inner banner  -->
    <section class="inner-banner">
        <h5>edit client</h5>
    </section>
</template>

<script>
export default {
    name: "EditCompany"
}
</script>

<style scoped>

</style>